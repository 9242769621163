module.exports = {
  "documentInteraction": {
    "name": "Document Interaction",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "action": [
        "click-move",
        "click-share",
        "click-preview",
        "click-download",
        "click-share-summary",
        "click-preview-summary",
        "click-reupload",
        "click-reupload-summary",
        "click-delete",
        "click-download-summary",
        "viewed-index",
        "viewed-summary",
        "click-share-recipient",
        "clicked-reps-meeting-link",
        "clicked-reps-email",
        "viewed-rep-profile",
        "document-download",
        "document-share",
        "document-view",
        "viewed-reps-profile",
        "set-to-private",
        "shared-with-users-and-teams",
        "shared-with-everyone",
        "open-view-usage-detail",
        "click-view-usage-date-expand",
        "click-summary"
      ]
    },
    "namespace": "documents"
  },
  "indexHoverActionInteraction": {
    "name": "Button hover",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "type": "string",
      "action": [
        "click-share",
        "click-download"
      ]
    },
    "namespace": "documents"
  },
  "uploadFileInteraction": {
    "name": "Upload file",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "source": "string",
      "action": [
        "upload-file-index"
      ]
    },
    "namespace": "documents"
  }
};