export const lastEntry = entries => entries.length > 0 ? entries[entries.length - 1] : null;
export function instrumentPresentationRequest() {
  if (window.newrelic && window.performance && window.performance.getEntriesByType) {
    const presentationRequestEntry = lastEntry(window.performance.getEntriesByType('resource').filter(entry => entry.name.indexOf('/presentations/v2/public/portals/') > -1));
    if (!presentationRequestEntry) {
      return;
    }
    window.newrelic.setCustomAttribute('presentationFetchDuration', presentationRequestEntry.duration);
    window.newrelic.setCustomAttribute('presentationFetchStartTime', presentationRequestEntry.startTime);
  }
}
export function instrumentConsentRequest() {
  if (window.newrelic && window.performance && window.performance.getEntriesByType) {
    const consentRequestEntry = lastEntry(window.performance.getEntriesByType('resource').filter(entry => entry.name.indexOf('/presentations/v1/gdpr/public/presentations/') > -1));
    if (!consentRequestEntry) {
      return;
    }
    window.newrelic.setCustomAttribute('consentRequestDuration', consentRequestEntry.duration);
    window.newrelic.setCustomAttribute('consentRequestStartTime', consentRequestEntry.startTime);
  }
}