import PortalIdParser from 'PortalIdParser';
import apiClient from '../utils//SPMRedirectNoAuthApiClient';
import PresentationRecord from '../records/PresentationRecord';
import { instrumentPresentationRequest } from '../utils/instrumentRequests';
const BASE_URL = 'presentations/v2/public';
export const fetchPresentation = (presentationId, accessId) => apiClient.get(`${BASE_URL}/portals/${PortalIdParser.get()}/presentations/${presentationId}/${accessId}`, {
  query: {
    portalId: PortalIdParser.get()
  }
}).then(presentation => {
  instrumentPresentationRequest();
  return PresentationRecord.create(presentation);
});
export const fetchPresentationDownloadUrl = (presentationId, accessId) => apiClient.get(`${BASE_URL}/portals/${PortalIdParser.get()}/presentations/${presentationId}/${accessId}/downloadUrl`);